<template>
  <div>
    <Header />
    <section class="bg-white d-lg-block d-none">
      <div class="container py-4">
        <table class="table table-responsive log-table-new">
          <thead>
            <tr>
              <td class="sortable-column fw-bold">ID</td>
              <td class="sortable-column fw-bold">Coin</td>
              <td class="sortable-column fw-bold">Price</td>
              <td class="sortable-column fw-bold">1h</td>
              <td class="sortable-column fw-bold">24h</td>
              <td class="sortable-column fw-bold">7d</td>
              <td class="sortable-column fw-bold">24h Volume</td>
              <td class="sortable-column fw-bold">Mkt Cap</td>
              <td class="sortable-column fw-bold">Last 7 Days</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(token, index) in tokenList"
              :key="index"
              class="align-items-center align-middle"
            >
              <td class="align-middle">{{ token.market_cap_rank }}</td>
              <td class="align-middle">
                <router-link
                  :to="'/token-details/' + token.id"
                  class="text-dark text-decoration-none"
                >
                  <div
                    class="
                      d-flex
                      justify-content-start
                      gap-2
                      align-items-center
                    "
                  >
                    <img :src="token.image" width="25px" height="25px" alt="" />
                    <div class="fw-bold">{{ token.name }}</div>
                    {{ token.symbol }}
                  </div></router-link
                >
              </td>
              <td class="align-middle">{{ token.current_price | toUSD }}</td>
              <td
                class="align-middle text-success fw-semibold"
                v-if="token.price_change_percentage_1h_in_currency > 0"
              >
                {{
                  token.price_change_percentage_1h_in_currency | toTwoDigits
                }}%
              </td>
              <td class="align-middle text-danger fw-semibold" v-else>
                {{
                  token.price_change_percentage_1h_in_currency | toTwoDigits
                }}%
              </td>
              <td
                class="align-middle text-success fw-semibold"
                v-if="token.price_change_percentage_24h_in_currency > 0"
              >
                {{
                  token.price_change_percentage_24h_in_currency | toTwoDigits
                }}%
              </td>
              <td class="align-middle text-danger fw-semibold" v-else>
                {{
                  token.price_change_percentage_24h_in_currency | toTwoDigits
                }}%
              </td>
              <td
                class="align-middle text-success fw-semibold"
                v-if="token.price_change_percentage_7d_in_currency > 0"
              >
                {{
                  token.price_change_percentage_7d_in_currency | toTwoDigits
                }}%
              </td>
              <td class="align-middle text-danger fw-semibold" v-else>
                {{
                  token.price_change_percentage_7d_in_currency | toTwoDigits
                }}%
              </td>
              <td class="align-middle">{{ token.total_volume | toUSD }}</td>
              <td class="align-middle">{{ token.market_cap | toUSD }}</td>
              <td class="align-middle">
                <trend
                  :data="token.sparkline_in_7d.price"
                  :gradient="['#128c7e', '#128c7e', '#128c7e']"
                  auto-draw
                  smooth
                  :height="50"
                  :width="150"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="my-4">
          <ul
            class="pagination pagination-md justify-content-center text-center"
          >
            <li class="page-item" :class="page === 1 ? 'disabled' : ''">
              <a class="page-link" @click="prevPage"> Previous </a>
            </li>
            <li class="page-link" style="background-color: inherit">
              Page {{ page }}
            </li>
            <li class="page-item" :class="page === lastPage ? '' : ''">
              <a class="page-link active" @click="nextPage"> Next </a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="d-lg-none d-block px-2">
      <div class="container py-4">
        <div class="table-responsive log-table-new">
          <table class="table">
            <thead>
              <tr>
                <td class="fw-bold">ID</td>
                <td class="fw-bold">Coin</td>
                <td class="fw-bold">Price</td>
                <td class="fw-bold">1h</td>
                <td class="fw-bold">24h</td>
                <td class="fw-bold">7d</td>
                <td class="fw-bold">24h Volume</td>
                <td class="fw-bold">Mkt Cap</td>
                <td class="fw-bold">Last 7 Days</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(token, index) in tokenList"
                :key="index"
                class="align-items-center align-middle"
              >
                <td class="align-middle">{{ token.market_cap_rank }}</td>
                <td class="align-middle">
                  <router-link
                    :to="'/token-details/' + token.id"
                    class="text-dark text-decoration-none"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-start
                        gap-2
                        align-items-center
                      "
                    >
                      <img
                        :src="token.image"
                        width="25px"
                        height="25px"
                        alt=""
                      />
                      <div class="fw-bold">{{ token.name }}</div>
                      {{ token.symbol }}
                    </div></router-link
                  >
                </td>
                <td class="align-middle">{{ token.current_price | toUSD }}</td>
                <td
                  class="align-middle text-success fw-semibold"
                  v-if="token.price_change_percentage_1h_in_currency > 0"
                >
                  {{
                    token.price_change_percentage_1h_in_currency | toTwoDigits
                  }}%
                </td>
                <td class="align-middle text-danger fw-semibold" v-else>
                  {{
                    token.price_change_percentage_1h_in_currency | toTwoDigits
                  }}%
                </td>
                <td
                  class="align-middle text-success fw-semibold"
                  v-if="token.price_change_percentage_24h_in_currency > 0"
                >
                  {{
                    token.price_change_percentage_24h_in_currency | toTwoDigits
                  }}%
                </td>
                <td class="align-middle text-danger fw-semibold" v-else>
                  {{
                    token.price_change_percentage_24h_in_currency | toTwoDigits
                  }}%
                </td>
                <td
                  class="align-middle text-success fw-semibold"
                  v-if="token.price_change_percentage_7d_in_currency > 0"
                >
                  {{
                    token.price_change_percentage_7d_in_currency | toTwoDigits
                  }}%
                </td>
                <td class="align-middle text-danger fw-semibold" v-else>
                  {{
                    token.price_change_percentage_7d_in_currency | toTwoDigits
                  }}%
                </td>
                <td class="align-middle">{{ token.total_volume | toUSD }}</td>
                <td class="align-middle">{{ token.market_cap | toUSD }}</td>
                <td class="align-middle">
                  <trend
                    :data="token.sparkline_in_7d.price"
                    :gradient="['#128c7e', '#128c7e', '#128c7e']"
                    auto-draw
                    smooth
                    :height="50"
                    :width="150"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="my-4">
          <ul
            class="pagination pagination-md justify-content-center text-center"
          >
            <li class="page-item" :class="page === 1 ? 'disabled' : ''">
              <a class="page-link" @click="prevPage"> Previous </a>
            </li>
            <li class="page-link" style="background-color: inherit">
              Page {{ page }}
            </li>
            <li class="page-item" :class="page === lastPage ? '' : ''">
              <a class="page-link active" @click="nextPage"> Next </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Token",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      cgPage: 1,
      tokenList: [],
      page: 1,
      perPage: 25,
    };
  },
  mounted() {
    this.getDataToken();
  },
  computed: {
    showRepos() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      return this.tokenList.slice(start, end);
    },
    lastPage() {
      let length = this.tokenList.length;
      return length / this.perPage;
    },
  },
  methods: {
    getDataToken() {
      axios
        .get(
          "https://api-data.dinodapps.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=25&page=" +
            this.cgPage +
            "&sparkline=true&price_change_percentage=1h%2C24h%2C7d%2C14d%2C30d%2C200d%2C1y"
        )
        .then((res) => {
          this.tokenList = res.data;
        });

      this.$mixpanel.track("Visit Token Page");
    },
    prevPage() {
      this.cgPage--;
      this.getDataToken();
      this.page--;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    nextPage() {
      this.cgPage++;
      this.getDataToken();
      this.page++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style>
tr {
  border-color: #e6e6e6;
}
.table > :not(:first-child) {
  border-top: none;
}
</style>